import * as React from 'react'

import getConfig from 'next/config'

import { AuthProvider, Routes, Types } from '@grandvisionhq/auth'

type AuthConfig = React.ComponentProps<typeof AuthProvider>['settings']

const { publicRuntimeConfig } = getConfig()
const apiUrl = publicRuntimeConfig.grandvision.apiUrl ?? ''
const {
  auth0Domain,
  auth0CustomDomain,
  auth0ClientId,
  putPrescriptionTokensOnCart,
  disableAddressEditing,
} = publicRuntimeConfig.grandvision.myAccount ?? {}

const routes: Routes = {
  createAccount: 'create',
  login: 'login',
  logout: 'logout',
  orders: 'orders',
  unlinkOrder: 'unlink-order',
  signup: 'signup',
  profile: 'profile',
  settings: 'settings',
  appointments: 'appointments',
  resetPassword: 'reset-password',
  linkExpired: 'link-expired',
  setPassword: 'set-password',
  changePassword: 'change-password',
  verify: 'verify',
  wishlist: 'wishlist',
}

const optIns: Types.OptInItem[] = [
  {
    titleKey: 'account.optIn.marketingEmails',
    value: 'subscribe-newsletter',
    tooltipKey: 'account.optIn.marketingEmails.tooltip',
    isInSignup: true,
  },
]

const supportFeaturesLinks: Types.SupportFeatureLink[] = [
  {
    label: 'account.menu.appointments',
    url: routes.appointments,
  },
  {
    label: 'account.menu.orders',
    url: routes.orders,
  },
  {
    label: 'account.menu.settings',
    url: routes.settings,
  },
]

export const getAuthConfig = (): AuthConfig => ({
  apiUrl,
  basePath: 'my-account',
  clientId: auth0ClientId,
  customDomain: auth0CustomDomain,
  domain: auth0Domain,
  routes,
  checkoutRoute: '/checkout/shipping',
  putPrescriptionTokensOnCart,
  disableAddressEditing,
  optIns,
  supportFeaturesLinks,
})
