export const promoteInstallmentsConfiguration = {
  minimumPrice: 7500,
  numberOfInstallments: 3,
  enabledProductTypes: ['sunglasses'],
}

export const graphPromoteInstallmentsConfiguration = {
  minimumPrice: 75,
  numberOfInstallments: 3,
}
