import type { FormatterMap, RouteList } from '@grandvisionhq/frk-routing'
import { createSimpleFormatter } from '@grandvisionhq/frk-routing'
import {
  createAuthFormatters,
  createCheckoutFormatters,
  createMyAccountFormatters,
  productDetailFormatters,
} from '@grandvisionhq/www-next/formatters'

export const localRouteFormatters: FormatterMap = {
  home: createSimpleFormatter('/'),
  'cart.overview': createSimpleFormatter('cart'),
  'appointments.book': createSimpleFormatter('pide-cita'),
  'appointments.cancel': createSimpleFormatter('cancel-appointment'),
  'appointments.reschedule': createSimpleFormatter('reschedule-appointment'),
  ...createAuthFormatters(),
  ...createMyAccountFormatters(),
  'express-checkout': createSimpleFormatter('express-checkout'),
  'order.confirm': createSimpleFormatter('order/confirm'),
  ...createCheckoutFormatters({
    path: 'checkout',
    children: { shipping: 'shipping', overview: 'overview', payment: 'payment' },
  }),
  ...productDetailFormatters({
    'contact-lenses': 'lentes-de-contacto',
    frames: 'gafas-graduadas',
    'ready-readers': 'gafas-de-lectura',
    solutions: 'solutions',
    sunglasses: 'gafas-de-sol',
  }),
  wishlist: createSimpleFormatter('/wishlist'),
}

export const routes: RouteList = [
  ['/', 'home'],

  ['/checkout/shipping', 'checkout.shipping'],
  ['/checkout/overview', 'checkout.overview'],
  ['/checkout/payment', 'checkout.payment'],
  ['/api/payment-callback', 'payment.callback'],
  ['/order/confirm', 'order.confirm'],

  ['/express-checkout', 'express-checkout'],

  ['/pide-tu-cita/(.*)?', 'appointments.book'],
  ['/cancela-tu-cita', 'appointments.cancel'],
  ['/reprograma-tu-cita', 'appointments.reschedule'],
]
