import type { Config } from '@grandvisionhq/shipping'

export const getDeliveryIndicatorConfig = (): Config => ({
  inventoryChannels: {
    // There is a bug in using the key as the cart does not return inventory channel keys.
    // test
    '583774ca-7dda-43c4-8a9c-3242bd7b0e82': {
      expectedDeliveryDays: {
        onStock: '2-3',
        outOfStock: '7',
      },
    },
    // acc
    'f690865c-a627-42d8-aba2-41d103e377d9': {
      expectedDeliveryDays: {
        onStock: '2-3',
        outOfStock: '7',
      },
    },
    // prod
    '9cc504a1-0d8e-4b33-9de7-1667f9a2addf': {
      expectedDeliveryDays: {
        onStock: '2-3',
        outOfStock: '7',
      },
    },
  },
  missingInventoryChannelStrategy: {
    expectedDeliveryDays: '10',
  },
  nextDayDeliveryTimeLimit: '17:30',
})
